.app-file {
  .circle-24 {
    margin-top: -24px;
    margin-bottom: 2px;
  }
}

.app-upload {
  input {
    position: absolute;
    opacity: 0;
  }
  fieldset {
     min-width: 0;
     padding: 0;
     margin: 0;
     border: 0;
   }
}

.app-upload__avatar img {
  //max-width: 160px;
  width: 160px;
  height: 160px;

  object-fit: cover;
  border-radius: 8px;

}

.file-container img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
  
  left: 50%;
  top: 50%;
  position: absolute;
  object-fit: cover;
  image-orientation: none;
  transform: translate(-50%, -50%);
}

.file-container {
  border-radius: 4px;
  width: 112px;
  height: 52px;
  padding: 0px;
  margin: 0px 6px;

  position: relative;
  cursor: auto;
}

.z-index-100 {
  z-index: 100;
}

.delete-file {
  position: relative;
}

.delete-file svg {
  position: absolute;
  top: 70px;
  left: 40px;
}

.gap-24 {
  gap: 24px;
}