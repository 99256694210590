.popup-delete-task {
    width: 370px;
    z-index: 1000;
    background-color: white;
    border-radius: 12px;
    padding: 16px;
}

.popup-delete-task-background {
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    background-color: #234D8499;

    display:grid;
    place-content: center;
}

.popup-delete-task-buttons {
    display: flex;
    justify-content: space-between;
    max-width: 240px;
    margin-top: 12px;
    position: relative;
}

.popup-delete-task-button-cancel {
    width: 100px;
    height: 30px;
    border-radius: 4px;
    border: 1.5px solid #234D84;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.popup-delete-task-button-confirm {
    width: 100px;
    height: 30px;
    border-radius: 4px;
    border: 1.5px solid #234D84;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.popup-delete-task-buttons > .on-hover {
    position: absolute;
    background: linear-gradient(63.66deg, #0A64CC 8%, #1486CC 50%, #29ADD4 100%),
    linear-gradient(251.16deg, #209CD0 32.83%, #0C6CCC 65.71%);
    transition: all 0.2s ease-out;
}

.popup-delete-task-buttons > .on-hover-cancel {
    position: absolute;
    right: 0;
    background: linear-gradient(63.66deg, #0A64CC 8%, #1486CC 50%, #29ADD4 100%),
    linear-gradient(251.16deg, #209CD0 32.83%, #0C6CCC 65.71%);
    transition: all 0.2s ease-out;
}

.opacity-0 {
    opacity: 0;
}