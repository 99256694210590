.app-boards {
  &__input {
    transition: height .2s;
    height: 55px;
    &.hidden {
      height: 0;
    }
  }
  &__column {
    width: 305px;
    height: calc(100vh - 181px);
    transition: .3s;
    border-radius: 4px;
    outline: 1px solid #fff;
    
    display: flex;
    flex-direction: column;

    &.active {
      transition: .4s;

      .app-boards__input {
       display: none;
      }
    }
    .active {
      transition: .4s;
    }

    &:hover {
      background: var(--color-board);
      outline: 1px solid var(--color-grey-border3);
    }
    svg {
      transition: .2s;
    }
    .btn-open-active {
      svg {
        transform: rotate(180deg);
      }
    }
    .js--board-hide {
      transition: .4s;
    }
    .board-hide {
      opacity: 0;
      z-index: -1;
      transition: .4s;
      width: 0;
      height: 0;
    }
  }
  &__top {
    min-height: 21px;

    .app-dropdown__open svg {
      path{
        stroke: var(--color-grey-image);
      }
    }
  }
  &__status {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  &__cards {
    max-height: 100%;
  }
}

.app-tabs__btn-task {
  position: absolute;
  z-index: 3;
  bottom: 40px;
  right: 0;
}

/* Dragula CSS Release 3.2.0 from: https://github.com/bevacqua/dragula */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.mWidth-220 {
  max-width: 220px;
}